/*
 |-----------------------------------------------------------------------------
 | components/atoms/Button/Button.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import dynamic from 'next/dynamic';
import { isEmpty } from 'lodash';
// import { ray } from 'node-ray/web';

import * as IButton from './types';

const Icon = dynamic(() => import('@/atoms/Icon'));
const Link = dynamic(() => import('next/link'));

const Button: FC<IButton.IProps> = ({
	isDisabled,
	label,
	onClick,
	path,
	type,
	url,
	utilities,
	variant,
}) => {
	// ray('Debug atom Button:', {
	// 	isDisabled: isDisabled,
	// 	label: label,
	// 	path: path,
	// 	type: type,
	// 	url: url,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).red();

	const classNames = `
		font-normal
		group
		inline-block
		px-8
		py-4
		rounded-3xl
		text-xl
		transition-colors
		disabled:opacity-25
		md:text-base
		${
			variant === 'primary'
				? 'bg-brand-1 text-brand-4 hover:bg-white hover:text-brand-1'
				: ''
		}
		${variant === 'secondary' ? 'bg-brand-2 text-brand-4' : ''}
		${
			variant === 'tertiary'
				? `
					border
					border-brand-2
					border-solid
					text-brand-2
					hover:bg-brand-2
					hover:text-brand-4
				`
				: ''
		}
		${
			variant === 'quaternary'
				? 'bg-brand-4 text-brand-1 hover:bg-white hover:text-brand-1'
				: ''
		}
		${
			variant === 'quinary'
				? 'bg-brand-1 text-brand-4 hover:bg-brand-4 hover:text-brand-1'
				: ''
		}
		${utilities ? utilities : ''}
	`;

	switch (true) {
		case !isEmpty(path):
			return (
				<Link href={path} legacyBehavior passHref>
					<a
						className={classNames}
						data-testid="button"
						href="jsx-a11y"
					>
						{label}
					</a>
				</Link>
			);
		case !isEmpty(url):
			return (
				<a
					className={classNames}
					data-testid="button"
					href={url}
					rel="external noopener noreferrer"
					target="_blank"
				>
					<span className={`flex items-center`}>
						{label}

						{/*<Icon*/}
						{/*	id="r-link"*/}
						{/*	utilities={`*/}
						{/*		fill-current*/}
						{/*		h-4*/}
						{/*		ml-4*/}
						{/*		opacity-25*/}
						{/*		transition-opacity*/}
						{/*		w-4*/}
						{/*		group-hover:opacity-100*/}
						{/*	`}*/}
						{/*/>*/}
					</span>
				</a>
			);
		default:
			return (
				<button
					className={classNames}
					data-testid="button"
					disabled={isDisabled}
					onClick={onClick}
					type={type}
				>
					{label}
				</button>
			);
	}
};

export default Button;
